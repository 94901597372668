import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import Checkbox from "rc-checkbox";
import { useEffect } from "react";
import axios from "axios";
import Config from "Config";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

const HeaderContainer = tw.div` w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full `;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row  items-center lg:items-stretch  lg:justify-center relative`;
const Plan = styled.div`
  ${tw`w-full max-w-md mt-16 lg:mr-8 lg:last:mx-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${(props) =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-xl text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-lg`}
  }
  .price {
    ${tw`font-bold text-3xl  my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium text-sm flex justify-between items-center `}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-lg font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`flex items-center justify-center w-full mt-5`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider h-12 px-16 flex items-center justify-center  text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  // description = "Our Products Pricing Packages.",
  description = "Discover our cost-effective packages for bulk SMS, Voice SMS, and Email marketing with TXTConnect. Our pricing is among the lowest and is designed to meet the needs of businesses ",
  plans = null,
  primaryButtonText = "Buy Now",
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [planData, setPlanData] = useState([[]]);
  const [checkedPlan, setCheckedPlan] = useState({
    SMS: "",
    EMAIL: "",
    VOICE: "",
  });
  const [isPlanFetched, setIsPlanFetched] = useState(false);

  const getPlans = async () => {
    try {
      const res = await axios.get(`${Config.apiUrl}/plans`);
      setPlanData(res?.data);
    } catch (error) {
      // console.log("get plans ", error?.response);
    }
  };

  useEffect(() => {
    if (!isPlanFetched) {
      setIsPlanFetched(true);
      getPlans();
    }
  }, [isPlanFetched]);

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(
        115deg,
        rgba(56, 178, 172, 1) 0%,
        rgba(129, 230, 217, 1) 100%
      );
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(
        115deg,
        #6415ff,
        #7431ff,
        #8244ff,
        #8e56ff,
        #9a66ff
      );
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(
        115deg,
        rgba(245, 101, 101, 1) 0%,
        rgba(254, 178, 178, 1) 100%
      );
    `,
  ];

  return (
    <Container id="pricing">
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {planData?.[0].map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && (
                <div
                  className="planHighlight"
                  css={
                    highlightGradientsCss[index % highlightGradientsCss.length]
                  }
                />
              )}
              <PlanHeader>
                {/* <span className="name">{plan.name}</span> */}
                <span className="price">{plan.name}</span>
                <span className="duration">{plan.subName}</span>
              </PlanHeader>
              <PlanFeatures>
                {/* <span className="feature mainFeature">{plan.mainFeature}</span> */}
                <div key={index} className="feature">
                  <p tw="text-black font-bold">Pack name (Qty.)</p>{" "}
                  <p tw="text-black font-bold">Price per Qty </p>
                </div>
                {plan?.packs?.map((pack, index) => (
                  <div key={index} className="feature">
                    <p> {pack?.name}</p>
                    {/* <Checkbox checked={checkedPlan[plan.name] == pack.name} /> */}
                    <p tw="text-gray-900">{pack?.price} GHS</p>
                  </div>
                ))}
              </PlanFeatures>
            </Plan>
          ))}
          <DecoratorBlob />
        </PlansContainer>
        <PlanAction>
          <BuyNowButton
            css={highlightGradientsCss[1]}
            onClick={() => (window.location = "/login")}
          >
            Buy Now
          </BuyNowButton>
        </PlanAction>
      </ContentWithPaddingXl>
    </Container>
  );
};
