import React, { useState, useEffect } from "react";
import Hero from "components/deliverability/TwoColWithTwoHorizontalFeaturesAndButton";
import FeatureAndButton from "components/deliverability/TwoColWithTwoHorizontalFeature";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Features from "components/deliverability/ThreeColCenteredStatsPrimaryBackground";
import Feature from "components/deliverability/ThreeColCenteredStatsEventPrimaryBackground";
import Images from "components/deliverability/ThreeColCentered";
import Strategy from "components/deliverability/Strategy";
import MessagingStep2 from "components/features/MessagingStep2";
import Event from "components/deliverability/VerticalWithAlternateImageAndText";
import Featuree from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating";
import Featuress from "components/features/TwoColWithTwoHorizontalFeaturesAndButton";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher";
import Blog from "components/deliverability/DashboardSixFeature";
import Blogs from "components/blogs/ThreeColSimpleWithImage";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight";
import Footer from "components/footers/MiniCenteredFooter";
import styled from "styled-components";
import { css } from "styled-components/macro";
import IframePopup from "pages/IframePopup";
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

export default ({}) => {
  return (
    <AnimationRevealPage>
      <Hero />
      <IframePopup />
      <Feature />
      <Event />
      {/* <Features /> */}
      {/* <Images /> */}

      {/* <MessagingStep2 /> */}
      {/* <Testimonial /> */}
      <Blog />
      <FeatureAndButton />
      {/* <GetStarted /> */}
      <Strategy />
      <Footer />
    </AnimationRevealPage>
  );
};
