const Config = {
  domain: "https://txtconnect.watchmyproduct.com",
  // domain: "https://20.83.155.145:3007",
  apiUrl: "https://txtconnect.watchmyproduct.com/backend/api",
  nodeApiUrl: "https://txtconnect.watchmyproduct.com/api",
  url: "https://youtu.be/46ZjlVwxvfI?si=qdcrq03uhaEWUmd4",
  messagingUrl: "https://txtconnect.watchmyproduct.com/messaging",
  voiceUrl: "https://txtconnect.watchmyproduct.com/voice-sms",
  emailUrl: "https://txtconnect.watchmyproduct.com/email",
  growthUrl: "https://txtconnect.watchmyproduct.com/growth",
  smsweloveurl: "https://txtconnect.watchmyproduct.com/sms-we-love",
  //domain: "https://staging.txtconnect.net",
  //apiUrl: "https://staging.txtconnect.net/backend/api",
  //nodeApiUrl: "https://staging.txtconnect.net/api",
};

export default Config;
