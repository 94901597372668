import React, { useState, useEffect } from "react";
import HeaderBelow from "components/help/Header";
import ContactUs from "components/about/ContactUs";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import BenefitsParterning from "components/features/ThreeColCenteredStatsPrimaryBackground";
import Strategy from "components/features/Strategy";
import EngagementStrategies from "components/features/MessagingStep2";
import CustomerEngagement from "components/features/VerticalWithAlternateImageAndText";

import SuccessStory from "components/blogs/GridWithFeaturedPost";
import styled from "styled-components";
import Footer from "components/footers/MiniCenteredFooter";
import TwoColumnWithVideo from "components/hero/TwoColumnWithVideo";
import ThreeColSimple from "components/help/ThreeColSimple";
import ThreeColSimpleGuide from "components/help/ThreeColSimpleGuide";
import TwoCol from "components/help/TwoCol";
import Header from "components/headers/light.js";
import IframePopup from "pages/IframePopup";
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;
export default ({}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(""); // Store active nav link state

  // Function to handle Product link click in Footer and update Navbar state
  const handleProductLinkClick = () => {
    setActiveNavLink("Products"); // Set active link in Navbar
    setIsSubMenuOpen(true); // Open the Products dropdown
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [user, setUser] = useState(false);
  return (
    <AnimationRevealPage>
      {/* <MaxWidth> */}
      <Header
        roundedHeaderButton={true}
        user={user}
        isSubMenuOpen={isSubMenuOpen}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      <IframePopup />
      <HeaderBelow />
      {/* <ThreeColSimple /> */}
      <ContactUs />
      {/* <ThreeColSimpleGuide /> */}
      {/* <TwoCol /> */}
      <Footer onProductLinkClick={handleProductLinkClick} />
      {/* </MaxWidth> */}
    </AnimationRevealPage>
  );
};
