import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {
  Container,
  Content2Xl,
  ContentWithVerticalPadding,
} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import Hero from "components/hero/TwoColumnWithInput.js";
import Header from "components/headers/light.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Platform from "components/features/Platform";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter";
import Images from "Images";
import ScrollToTopButton from "pages/ScrollToTopButton";
import IframePopup from "pages/IframePopup";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
const HeroRow = tw(
  Row
)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const UpdateNotice = tw(
  Column
)`w-full flex-auto mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

const TextColumn = tw(
  Column
)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(
  HeadingBase
)`text-center lg:text-left text-primary-900 leading-snug`;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start `;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

const CustomersLogoStrip = styled.div`
  ${tw` !mt-0  max-w-4xl mx-auto text-center`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`w-full`}
  }
`;

const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionHeading = tw(HeadingBase)``;
const SectionDescription = tw(
  DescriptionBase
)`text-center mx-auto text-gray-600 max-w-4xl`;

const PreviewCards = tw.div`flex flex-wrap -mr-12`;
const PreviewCardContainer = tw.div`mt-24 mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 pr-12`;
const PreviewCard = tw(motion.a)`block rounded-lg shadow-raised`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const PreviewCardImage = styled(motion.div)`
  ${(props) =>
    css`
      background-image: url("${props.$imageSrc}");
    `}
  ${tw`h-128 md:h-144 bg-cover bg-left-top`}
`;
const PreviewButton = tw(
  PrimaryButtonBase
)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;

const ComponentsContainer = tw.div`mt-24`;
const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
const ComponentName = tw.h6`text-lg`;
const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

const HighlightedText = tw.span`text-primary-500`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

// start

const PopupWrapper = tw.div`fixed inset-0 z-50  bg-black bg-opacity-50 flex items-center justify-center`;

const PopupContent = tw.div`bg-white p-8 rounded-lg max-w-2xl`;

const PopupCloseButton = tw.button`absolute top-0 right-0 text-gray-700`;

const IFrameContainer = styled.div`
  ${tw`w-[400px] h-[535px]`}

  iframe {
    ${tw`w-full h-full`}
  }
`;

// end
export default ({
  features = null,
  primaryButtonUrl = "#landingPageDemos",
  primaryButtonText = "Explore Demos",
  secondaryButtonUrl = "#componentDemos",
  secondaryButtonText = "View Components",
  buttonRoundedCss = "",
  landingPages,
  innerPages,
  blocks,
}) => {
  const [user, setUser] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(""); // Store active nav link state
  // start

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      // Set showPopup to true when the user scrolls, adjust the threshold as needed
      if (window.scrollY > 200) {
        setShowPopup(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };
  // end
  // Function to handle Product link click in Footer and update Navbar state
  const handleProductLinkClick = () => {
    setActiveNavLink("Products"); // Set active link in Navbar
    setIsSubMenuOpen(true); // Open the Products dropdown
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, []);

  const previewImageAnimationVariants = {
    rest: {
      backgroundPositionY: "0%",
    },
    hover: {
      backgroundPositionY: "100%",
      transition: { type: "tween", ease: "linear", duration: 5 },
    },
  };

  const featureCards = [
    {
      imageSrc: Images.MultiPurpose,
      title: "Multi-Purpose Messaging",
      description:
        "Send all kinds of messages to as many people as possible, quickly and effortlessly.",
    },
    {
      imageSrc: Images.OmniChannel,
      title: "Omni-Channel Communication",
      description:
        "Pick between SMS, Email, or Voice for the best method to broadcast your message.",
    },
    {
      imageSrc: Images.QuickEasy,
      title: "Quick & Easy Setup",
      description:
        "Sign up for a free account and send your first message blast within seconds!",
    },
  ];

  const Steps = [
    {
      heading: "Upload Your Contact List",
      description:
        "Upload or enter a list of emails or phone numbers, or import contacts via your CRM.",
    },
    {
      heading: "Compose Your Message",
      description:
        "Choose a channel (SMS, Email, Voice) and write or vocalize an original message or select a pre-made template.",
    },
    {
      heading: "Mass Send Your Message",
      description:
        "Hit “send” to send your message immediately, or schedule a specific time to send out your message automatically.",
    },
  ];
  return (
    <AnimationRevealPage>
      <Header
        roundedHeaderButton={true}
        user={user}
        isSubMenuOpen={isSubMenuOpen}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      <Hero />
      <MaxWidth>
        <ScrollToTopButton />
        <IframePopup />
        {/* start */}
        {/* {showPopup && (
          <PopupWrapper>
            <PopupContent>
              <PopupCloseButton onClick={closePopup}>Close</PopupCloseButton>
              <IFrameContainer>
                <iframe
                  // width="540"
                  // height="100%"
                  src="https://2107637b.sibforms.com/serve/MUIFAMAPW-FoW_hNyYUhNMJ6IAJIMkK9IGxQru9mQNyLdU8YRT9xP7gXipzHfZ0r9nJ4VwAk7-BJLEzYT1TS5lv2_KJGMawramzaYdq6EQkmiuFUi3v9oz9sWhtz9ftp5bpbSm-hkVVUVsvNm0B0-4NsislUhHugSZv8_ualRTD9QLWK-ICxklmfquPGCmiNambzN72o3Y-rPyN6"
                  frameborder="0"
                  scrolling="auto"
                  allowfullscreen=""
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "100%",
                  }}
                ></iframe>
              </IFrameContainer>
            </PopupContent>
          </PopupWrapper>
        )} */}

        {/* end */}
        <div>
          <CustomersLogoStrip>
            <SectionHeading>
              Our Trusted Customers
              {/* <span tw="text-primary-900"> Customers</span> */}
            </SectionHeading>
            <img src={Images.PartnersLogos} alt="Our Customers" />
          </CustomersLogoStrip>
        </div>

        <Features
          heading={
            <>
              Reasons To Choose TXTConnect.
              {/* <HighlightedText>TXTConnect.</HighlightedText> */}
            </>
          }
          description={
            "TXTConnect is the most effective online mass communication platform for organizations in any industry."
          }
          cards={featureCards}
        />
        <Platform />
        <FeatureWithSteps
          subheading={<Subheading>STEPS</Subheading>}
          steps={Steps}
          heading={
            <>
              How TXTConnect Works For Online SMS.
              {/* <HighlightedText>Txt Connect</HighlightedText>  */}
            </>
          }
          textOnLeft={false}
          imageSrc={Images.Cms}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        {/* <MainFeature2
          subheading={<Subheading>VALUES</Subheading>}
          heading={
            <>
              We Always Abide by Our{" "}
              <HighlightedText>Principles.</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "Affordable",
              description:
                "We promise to offer you the best rate we can - at par with the industry standard.",
              iconContainerCss: tw`bg-green-300 text-green-800`,
            },
            {
              Icon: BriefcaseIcon,
              title: "Professionalism",
              description:
                "We assure you that our templates are designed and created by professional designers.",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        /> */}
        <Pricing
          subheading={<Subheading>Pricing</Subheading>}
          heading={
            <>
              Affordable Bulk Messaging Solutions <br /> Tailored For You
              {/* Reasonable & Flexible <HighlightedText>Plans.</HighlightedText> */}
            </>
          }
        />
        <Testimonial
          subheading={<Subheading>Testimonials</Subheading>}
          heading={
            <>
              Our Awesome Customers
              {/* <HighlightedText>Customers.</HighlightedText> */}
            </>
          }
          testimonials={[
            {
              imageSrc: Images.TestimonialLogo1,
              quote:
                "On a scale of 1 to 100, with one being the lowest satisfaction and 100 being maximum satisfaction, I would give TXTConnect a rating of 90. When we started using TXTConnect, our marketing improved significantly.",
              customerName: "Jane",
              customerTitle: "Marketing Manager at Tang Palace Hotel",
            },
            {
              imageSrc: Images.TestimonialLogo2,
              quote:
                "Before TXTConnect, Reviv Ghana used to call its clients to tell them about promotions and off days. However, as we grew and the number of clients we cared for increased, calling everyone became impossible. Bulk SMS—a solution from TXTConnect—solved our problem.",
              customerName: "Appiah",
              customerTitle: "Marketing Manager at Reviv Ghana",
            },
            {
              imageSrc: Images.TestimonialLogo3,
              quote:
                "After using the TXTConnect Bulk SMS service, I have been satisfied with the results. On a scale of 1 to 10, I would rate the product an 8 or a 9. However, I would not give the product a perfect rating because it does not deliver SMS internationally. That is something I would like to see implemented on TXTConnect in the future.",
              customerName: "Richard ",
              customerTitle: "IT—CIMAF",
            },
            {
              imageSrc: Images.TestimonialLogo4,
              quote:
                "Fidelio Savings is a micro-finance organization; therefore, we needed to keep our customers updated about the transactions happening in their accounts. We wanted them to be sure that when they transferred money into their account, it did not end up somewhere else. To make all this possible, we started using TXT Connect bulk SMS in 2019. We have been able to keep our customers updated and are satisfied with the TXT Connect Bulk SMS services.",
              customerName: "James",
              customerTitle: "Fidelio Savings",
            },
          ]}
        />
        <FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              You Have Questions ?
              {/* <HighlightedText>Questions ?</HighlightedText> */}
            </>
          }
          faqs={[
            {
              question: "Why Should I Use TXTConnect Bulk SMS Service ?",
              answer: (
                <>
                  <p>
                    If you have a contact list with thousands of customers,
                    manually sending messages will be taxing and expensive.
                  </p>
                  <br />
                  <p>
                    Moreover, if you must send out messages when your customers
                    request them—say, you are a bank and must send messages when
                    clients request account balances—you have to always be on
                    your toes to deliver the messages immediately.
                  </p>
                  <br />
                  <p>
                    TXTConnect eliminates these hassles. With a simple tap of a
                    single button, you can send your messages to thousands of
                    people in one go. Moreover, you can automate the messaging
                    process to deliver messages to customers at the right time
                    and on time.
                  </p>
                </>
              ),
            },

            {
              question: "How Many SMSs Can I Send Per Hour ?",
              answer: (
                <>
                  <p>
                    TXTConnect allows you maximum flexibility regarding the
                    number of messages you can send at a go:
                  </p>
                  <br />
                  <ul>
                    <li>
                      • If you choose the 10-digit number, you can send 3,600
                      SMSs per hour.
                    </li>
                    <li>
                      • If you opt for a quick SMS code, you can send 360,000
                      SMSs per hour.
                    </li>
                  </ul>
                </>
              ),
            },
            {
              question: "Is Mass Texting Legal in Ghana ?",
              answer: (
                <>
                  <b>Yes, it is 100% legal.</b>
                  <br />
                  <p>
                    However, you must ensure the people you want to message have
                    opted out of their own volition to receive messages from
                    you. Therefore, mass texting can only attract legal trouble
                    if you add people’s contact numbers to your contact list
                    without their approval.
                  </p>
                </>
              ),
            },
            {
              question: "How Can I Legally Acquire Contact Numbers ?",
              answer: (
                <>
                  <b>Easy: Rent a keyword and advertise it.</b>
                  <br />
                  <p>
                    A good example would be renting a keyword like JOIN. You can
                    then ask your target audience to join your contact list by
                    sending the rented keyword to a specific shortcode.
                  </p>
                  <br />
                  For example:
                  <br />
                  “Text JOIN to 33456 to receive informative updates about
                  [company name].”
                  <br />
                  Alternatively, you can opt for a 10-digit number.
                  <br />
                  For example:
                  <br />
                  “Text JOIN to 059 256 3345 to receive informative updates
                  about [company name].”
                </>
              ),
            },
            {
              question: "What is a ‘Short Code’ in SMS Marketing ?",
              answer: (
                <>
                  <p>
                    A Short Code is a 4-, 5-, or 6-digital code your company or
                    brand uses when receiving or sending messages. Remember,
                    short is good. A short string of numbers is easier for your
                    customer to remember and type on their phone whenever they
                    want to talk to you via SMS.
                  </p>
                  <br />
                  <p>
                    Also, a short code can be handy when you need people to join
                    your contact list:
                  </p>
                  <br />
                  “Text JOIN to 33456 to receive informative updates about
                  [company name].”
                </>
              ),
            },
            {
              question: "What is a ‘Keyword’ in SMS Marketing ?",
              answer: (
                <>
                  <p>
                    A keyword is a short word—it can be a phrase—that your
                    audience can text to you using a shortcode. The keyword
                    triggers a specific action you’ve preset on the TXTConnect
                    platform.
                  </p>
                  <br />
                  <p>
                    For example, in the above example, the keyword “JOIN” adds
                    the audience to your contact list. Your audience can send
                    the keyword “STOP” to unsubscribe from your contact list.
                  </p>
                </>
              ),
            },
            {
              question: "What is Voice Broadcasting ?",
              answer: (
                <>
                  <p>
                    Also known as phone broadcast, voice broadcasting is a mass
                    communication procedure that sends calls to tens, hundreds,
                    and even thousands of recipients in one go.
                  </p>
                  <br />
                  <p>
                    Instead of calling hundreds of contact numbers one by one,
                    you record your voice message, upload your contact numbers
                    on TXTConnect, and TXTConnect calls all the numbers in one
                    go and plays your voice message.
                  </p>
                  <br />
                  <p>Voice broadcasting saves you both time and money.</p>
                </>
              ),
            },
            {
              question: "Why Should I Use Your Bulk Email Service ?",
              answer: (
                <>
                  <p>
                    Standard email services limit the number of emails you can
                    send daily. For example, Google Mail allows you to send 500
                    emails daily.
                  </p>
                  <br />
                  <p>
                    So, what happens when you need to send thousands of emails
                    in one go? This is where the TXTConnect Bulk Email Service
                    comes in. TXTConnect Bulk Email eliminates all limitations,
                    allowing you to send as many emails as desired.
                  </p>
                </>
              ),
            },
            {
              question:
                "How Much Do Bulk SMS, Voice Broadcast, and Bulk Email Cost on TXTConnect ?",
              answer: (
                <>
                  <p>
                    This is a tough one. It is more like asking how much it
                    would cost to cool down your house in Accra. In such a case,
                    it depends on your house’s size—that is, the number of rooms
                    in that house. Cooling down a 2-bedroom home will cost less
                    than cooling a 10-bedroom house.
                  </p>
                  <br />
                  <p>
                    Similarly, the cost of Bulk SMS, Voice Broadcast, and Bulk
                    Email on TXTConnect depend on the number of messages you
                    intend to send. We have the best rates, and as your number
                    of messages increases, we offer you sizeable discounts.
                  </p>
                </>
              ),
            },
            {
              question:
                "Can I Do a One-Time Bulk SMS, Email, or Voice Broadcast Run ?",
              answer: (
                <>
                  <b>Yes!</b>
                  <br />
                  <p>
                    TXTConnect is all about ensuring you are delighted. We allow
                    our customers to renew subscriptions of their own volition.
                    Therefore, you can do a one-time broadcast run and cancel
                    your subscription.
                  </p>
                </>
              ),
            },
            {
              question: "Do You Offer Phone Support ?",
              answer: (
                <>
                  <b>Yes!</b>
                  <br />
                  <p>
                    If you are trying to send a Voice Broadcast, Bulk SMS, or
                    Bulk Email on TXTConnect and can’t figure out how to go
                    about it, do not hesitate to contact us.
                  </p>
                  <br />
                  <p>
                    <b>Call us at +233 272 747 529,</b> and one of our
                    specialists will be more than happy to guide you through the
                    entire process. Alternatively, you can always let our
                    professionals handle the process on your behalf.
                  </p>
                </>
              ),
            },
            {
              question: "Do I Need to Sign a Long-Term Agreement ?",
              answer: (
                <>
                  <b>No!</b>
                  <br />
                  <p>
                    TXTConnect subscriptions are based on the number of SMSs,
                    Emails, or Voice Broadcasts you plan to send. You can cancel
                    your subscription at any time.
                  </p>
                </>
              ),
            },
            {
              question: "How Long a Voice Message Can I Record ?",
              answer: (
                <>
                  <p>
                    TXTConnect does not limit the length of your pre-recorded
                    voice message. However, we highly recommend 30-second-long
                    voice messages. Our research has shown that most recipients
                    tolerate a maximum of 30 seconds while listening to a voice
                    broadcast.
                  </p>
                </>
              ),
            },
            {
              question: "Do You Have a Reporting System ?",
              answer: (
                <>
                  <b>Yes, we do!</b>
                  <br />
                  <p>
                    TXTConnect provides an extensive SMS, Voice, and Email
                    broadcast reporting system. The reports will give you a
                    complete overview of your campaigns.
                  </p>
                </>
              ),
            },
            {
              question:
                "If I am Trying to Send a Voice Message and the Phone is Unanswered, What Happens ?",
              answer: (
                <>
                  <p>
                    TXTConnect is smart enough to detect and note when a call
                    goes unanswered. If the call is not answered—busy signal or
                    no answer—TXTConnect will attempt to call the number again.
                    If the system fails to deliver your message, we will
                    indicate so in our reporting system. This will help you know
                    which numbers require special attention.
                  </p>
                </>
              ),
            },
            {
              question:
                "Do You Offer Voice Recording Services Alongside Your Voice Broadcasting Service ?",
              answer: (
                <>
                  <p>
                    Sometimes, you may want to broadcast a voice message but
                    cannot record it. Well, worry not.
                  </p>
                  <br />
                  <p>
                    Send us the message you want us to deliver in text format.
                    Our team of leading voice professionals will turn the
                    message into voice. Our sound engineers will also ensure the
                    sound is clear and crisp before we play it to your contact
                    list.{" "}
                  </p>
                </>
              ),
            },

            {
              question: "Is TXTConnect data protection compliant?",
              answer: (
                <p>
                  Yes. Our data protection supervisor reviews all bulk messaging
                  done using our platform to be sure it’s not in breach of any
                  data protection policy.
                </p>
              ),
            },
          ]}
        />
        <GetStarted />
      </MaxWidth>

      <Footer maxWidth={1200} onProductLinkClick={handleProductLinkClick} />
    </AnimationRevealPage>
  );
};
