import React, { useState, useEffect } from "react";
import EmailServices from "components/email/EmailServices";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Stats from "components/email/Stats";

import Strategy from "components/email/Strategy";

import CustomerEngagement from "components/email/CustomerEngagement";

import PlatformCapabilities from "components/email/PlatformCapabilities";

import Footer from "components/footers/MiniCenteredFooter";
import styled from "styled-components";
import Header from "components/headers/light.js";
import IframePopup from "pages/IframePopup";
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;
export default ({}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(""); // Store active nav link state

  // Function to handle Product link click in Footer and update Navbar state
  const handleProductLinkClick = () => {
    setActiveNavLink("Products"); // Set active link in Navbar
    setIsSubMenuOpen(true); // Open the Products dropdown
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [user, setUser] = useState(false);
  return (
    <AnimationRevealPage>
      {/* <MaxWidth> */}
      <Header
        roundedHeaderButton={true}
        user={user}
        isSubMenuOpen={isSubMenuOpen}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      <IframePopup />
      <EmailServices />
      <CustomerEngagement />
      {/* <Stats />

      <PlatformCapabilities />

      <Strategy /> */}
      <Footer onProductLinkClick={handleProductLinkClick} />
      {/* </MaxWidth> */}
    </AnimationRevealPage>
  );
};
