import React, { useState, useEffect } from "react";
import ClientGuidance from "components/clientStrategy/ClientGuidance";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Stats from "components/clientStrategy/Stats";
import Strategy from "components/clientStrategy/Strategy";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import CustomerSupport from "components/clientStrategy/CustomerSupport";
import tw from "twin.macro";

import SMSClient from "components/clientStrategy/SMSClient";
import Footer from "components/footers/MiniCenteredFooter";
import styled from "styled-components";
import Images from "Images";
import Header from "components/headers/light.js";
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";
import IframePopup from "pages/IframePopup";
const CustomersLogoStrip = styled.div`
  ${tw` mt-4 max-w-4xl mx-auto text-center`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`w-full`}
  }
`;
const SectionHeading = tw(HeadingBase)``;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

export default ({}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(""); // Store active nav link state

  // Function to handle Product link click in Footer and update Navbar state
  const handleProductLinkClick = () => {
    setActiveNavLink("Products"); // Set active link in Navbar
    setIsSubMenuOpen(true); // Open the Products dropdown
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [user, setUser] = useState(false);
  return (
    <AnimationRevealPage>
      {/* <MaxWidth> */}
      <Header
        roundedHeaderButton={true}
        user={user}
        isSubMenuOpen={isSubMenuOpen}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      <IframePopup />
      <ClientGuidance />

      <CustomerSupport />
      <Stats />
      {/* <div>
        <HeadingInfoContainer>
          <HeadingTitle>Recognized by global industry awards</HeadingTitle>
        </HeadingInfoContainer>
        <CustomersLogoStrip>
          <img src={Images.Client5} alt="Our Customers" />
        </CustomersLogoStrip>
      </div>
      <SMSClient />

      <Strategy /> */}
      <Footer onProductLinkClick={handleProductLinkClick} />
      {/* </MaxWidth> */}
    </AnimationRevealPage>
  );
};
