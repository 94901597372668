import React, { useState, useEffect } from "react";
import RevenueManagement from "components/revenue/RevenueManagement";
import ContactUs from "components/revenue/ContactUs.js";
import CustomerEngagement from "components/audiencemanager/CustomerEngagement";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Stats from "components/audiencemanager/Stats";
import SmsSegmentation from "components/audiencemanager/SmsSegmentation";
import Strategy from "components/audiencemanager/Strategy";

import SuccessStory from "components/audiencemanager/SuccessStory";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import styled from "styled-components";
import { css } from "styled-components/macro";
import NewRevenueCalculator from "components/revenue/NewRevenueCalculator";
import IframePopup from "pages/IframePopup";
const MaxWidth = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`;

export default ({}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeNavLink, setActiveNavLink] = useState(""); // Store active nav link state

  // Function to handle Product link click in Footer and update Navbar state
  const handleProductLinkClick = () => {
    setActiveNavLink("Products"); // Set active link in Navbar
    setIsSubMenuOpen(true); // Open the Products dropdown
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [user, setUser] = useState(false);
  return (
    <AnimationRevealPage>
      {/* <MaxWidth> */}
      <Header
        roundedHeaderButton={true}
        user={user}
        isSubMenuOpen={isSubMenuOpen}
        activeNavLink={activeNavLink}
        setActiveNavLink={setActiveNavLink}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      <IframePopup />
      <RevenueManagement />
      <ContactUs />
      <Footer onProductLinkClick={handleProductLinkClick} />
      {/* </MaxWidth> */}
    </AnimationRevealPage>
  );
};
